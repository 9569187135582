import React from 'react'
import TrainingTemplate from 'components/templates/TrainingTemplate/TrainingTemplate'

export default function TrainingHaccpPage() {
  const seoKeywords = ['SEO0', 'SEO1', 'SEO2']

  const productName = 'HACCP'

  const description =
    'L’<b>HACCP</b> (<b><em>Hazard Analysis and Critical Control Points</em></b>) è un sistema di autocontrollo che ogni operatore nel settore della produzione e somministrazione di alimenti deve mettere in atto al fine di valutare e stimare pericoli e rischi e stabilire misure di controllo per prevenire l’insorgere di problemi igienici e sanitari.<br> Non avere un <b>piano di autocontrollo HACCP</b> secondo il Regolamento CE n. 852/2004 è reato punito con una sanzione di ca <b>2000,00 €</b><br> ING GROUP offre tutta la formazione per:<br><br> <ul><li>Rispetto delle specifiche procedure operative del sistema di autocontrollo</li> <li>Gestione periodica della corretta applicazione del sistema di autocontrollo.</li> <li>Gestione del Programma di corretta sanificazione delle attrezzature e degli ambienti di lavoro.</li>'

  const description1 = null

  return (
    <>
      <TrainingTemplate
        seoKeywords={seoKeywords}
        productName={productName}
        description={description}
        description1={description1}
      />
    </>
  )
}
